<script lang="ts" setup>
import type { ProfileDetails, Section, SubSection } from '@forgd/contract'
import { createTemplatePromise } from '@vueuse/core'
import { isTdFormDirty, isTdFormValid } from '#td/composables/form'
import type { TDContextResolved } from '#td/composables/context'
import UiResources from '#core/components/resources/UiResources.vue'

const route = useRoute()
const auth = useAuth()
const td = useTD()
await td.fetchTD()

// make sure they have selected a template before accessing other pages
if (!td.tokenDesigner?.template && route.path !== '/token-designer') {
  navigateTo('/token-designer')
}

const tokenProfileDetails = computed<ProfileDetails>(() => {
  const tokenProfile = tdSections.value?.find(s => s.slug === 'token-profile')
  const basicInformation = tokenProfile?.subSections?.find(f => f.slug === 'basic-information')
  const profileDetails = basicInformation?.fields.find(f => f.slug === 'profile-details')
  const values = profileDetails?.values as ProfileDetails
  return {
    name: values?.name || auth.project?.name || '',
    ticker: values?.ticker || auth.project?.ticker || '',
    website: values?.website || auth.project?.link || '',
    image: values?.image || auth.project?.image || '',
  }
})

interface SubSectionWithTitle extends SubSection {
  title?: string
}

const currentSection = ref<Section | null>(null)
const currentSubSection = ref<SubSectionWithTitle | null>(null)

// TODO td context should be reactive if possible
function resolveTdContext() {
  // route path does not get flushed at the correct time
  const path = window.location.pathname
  const [sectionSlug, subSectionSlug] = path.split('/').slice(-2)
  const section = tdSections.value?.find(s => s.slug === sectionSlug)
  const subSection = section?.subSections.find(
    s => s.slug === subSectionSlug,
  )
  currentSection.value = section ?? null
  currentSubSection.value = subSection ?? null
}

onMounted(() => {
  resolveTdContext()
})

const toast = useAppToast()
const isMarkingComplete = ref(false)
async function markSubSectionComplete() {
  resolveTdContext()

  // early bail to prevent ts error squigglies everywhere
  if (!auth.project?.id) {
    return
  }
  if (!td.context.currentSection) {
    return
  }
  if (!td.context.currentSubSection) {
    return
  }

  isMarkingComplete.value = true
  // we should trigger a save if the form is dirty
  if (isTdFormDirty.value) {
    if (!await saveTdForm()
      .catch(() => {
        return false
      }).then(() => {
        return true
      })) {
      return
    }

    resolveTdContext()
  }
  const complete = !td.context.currentSubSection.completed // toggle
  const sectionSlug = td.context.currentSection.slug
  const subSectionSlug = td.context.currentSubSection.slug
  const saveFn = sectionSlug === 'token-profile'
    ? td.putTokenProfile
    : sectionSlug === 'modeling'
      ? td.putModeling
      : sectionSlug === 'valuation-documenting'
        ? td.putValuation
        : sectionSlug === 'adjusting'
          ? td.putAdjusting
          : () => {
              throw new Error(`Unknown section ${sectionSlug}`)
            }
  await saveFn({
    params: {
      projectId: auth.project.id,
    },
    query: {
      complete: complete ? 'true' : 'false',
    },
    body: (sectionSlug !== 'adjusting' || subSectionSlug === 'simulating-post-tge-pops')
      ? {
          fields: currentSubSection.value.fields[0].values,
          subSectionId: currentSubSection.value.id,
        }
      : {
          fields: { empty: true },
          subSectionId: currentSubSection.value.id,
        },
  })
    .then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        // TODO the response may depending on the saveFn but TP returns all sections
        if (res.body?.tokenDesigner?.sections) {
          updateSections(res.body.tokenDesigner.sections)
          resolveTdContext()
        }
        else if (res.body?.sections) {
          updateSections(res.body.sections)
          resolveTdContext()
        }
        // push to next page
        if (complete) {
          // Don't change page for now
          // surroundBy.value[1] && navigateTo(surroundBy.value[1].to)
          toast.success({
            title: 'Section Completed, great job!',
          })
        }
      }
      else {
        toast.error({
          title: 'Section is not complete',
          description: 'Please provide all fields before marking the section as complete.',
        })
      }
    })
    .finally(() => {
      isMarkingComplete.value = false
    })
}

provide('resolveTdContext', resolveTdContext)

provide<() => Promise<TDContextResolved>>('tdContext', async () => {
  await resolveTdContext()
  return {
    tokenProfileDetails,
    currentSection,
    currentSubSection,
    fields(slug: string) {
      return currentSubSection.value?.fields.find(f => f.slug === slug)?.values
    },
  }
})

useHead({
  title: () => currentSubSection.value?.title || currentSubSection.value?.label,
  titleTemplate: '%s %separator Token Designer',
  templateParams: {
    separator: '-',
  },
})

const surroundBy = computed(() => {
  // using the nav list, we should figure out what the prev / next page is, if the index is 0 we don't show prev
  // we need to check children only
  const children = td.nav.flatMap(item => item.children)
  const index = children.findIndex(item => item.to === route.path)
  // handle being at the start or end of the items
  if (index === 0) {
    return [null, children[index + 1]]
  }
  else if (index === children.length - 1) {
    return [children[index - 1], null]
  }
  else {
    return [children[index - 1], children[index + 1]]
  }
})

async function saveTdForm() {
  const nuxtApp = useNuxtApp()
  await nuxtApp.hooks.callHook('forgd:td-form-submission')
}

const selectedTemplate = computed(() => {
  return !!td.tokenDesigner?.template
})

const { environment } = useRuntimeConfig()

/**
 * Navigation guard to warn against losing unsaved changes
 */
const showConfirmNavigationModal = ref(false)
const ConfirmNavigationPromise = createTemplatePromise<boolean>()
addRouteMiddleware(async (to, from) => {
  if (from.path.startsWith('/token-designer/') && isTdFormDirty.value) {
    showConfirmNavigationModal.value = true
    const confirmed = await ConfirmNavigationPromise.start()
    if (confirmed) {
      isTdFormDirty.value = false
    }
    else {
      return false
    }
  }
  showConfirmNavigationModal.value = false
  return true
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard" :expanded="false">
      <template #header>
        <div class="flex gap-4 items-center">
          <img src="/app/illustrations/token-designer-alt.svg" alt="Token Designer Icon" width="32" height="32">
          <div class="text-xl font-semibold flex-shrink-0">
            Token Designer
          </div>
        </div>
      </template>
      <template v-if="selectedTemplate" #sidebar>
        <div class="h-full border-r border-forgd-bgd-600 w-[230px]">
          <nav
            aria-label="Token Designer Navigation"
            class="py-4 px-2 flex-shrink-0 sticky top-0"
          >
            <UButton
              color="gray"
              variant="ghost"
              to="/token-designer/"
              active-class="bg-forgd-bgd-400"
              class="w-full h-[32px]"
            >
              <span class="text-xs font-semibold text-forgd-primary-900">
                Dashboard
              </span>
            </UButton>

            <UAccordion :key="td.currentSubMenu" :items="td.nav">
              <template #default="{ item, open }">
                <UButton color="gray" variant="ghost">
                  <span class="text-xs font-semibold text-forgd-primary-900">
                    {{ item.label }}
                  </span>
                  <template #trailing>
                    <UIcon
                      name="i-heroicons-chevron-down-20-solid"
                      class="w-5 h-5 ms-auto transform transition-transform duration-200 text-forgd-primary-900"
                      :class="[open && 'rotate-180']"
                    />
                  </template>
                </UButton>
              </template>
              <template #item="{ item }">
                <ul class="space-y-1">
                  <li v-for="(child, key) in item.children" :key="key">
                    <UButton
                      color="gray"
                      variant="ghost"
                      size="2xs"
                      :to="child.children ? undefined : child.to"
                      active-class="bg-forgd-bgd-400"
                      class="w-full font-normal gap-2"
                      :ui="{ padding: { '2xs': 'p-2' } }"
                    >
                      <UIcon
                        :name="child.icon"
                        class="w-[14px] h-[14px] text--bgd-200 flex-shrink-0 "
                        :class="{ 'text-green-600': child.completed }"
                      />
                      <div>{{ child.label }}</div>
                    </UButton>
                  </li>
                </ul>
              </template>
            </UAccordion>
          </nav>
        </div>
      </template>
      <div>
        <div>
          <!-- top content -->
          <template v-if="td.context.currentSubSection">
            <TdHeroCard>
              <template #title>
                {{ td.context.currentSubSection?.title || td.context.currentSubSection.label }}
              </template>
              <template #body>
                <div v-html="td.context.subSectionMeta.description" />
              </template>
              <template #actions>
                <div class="flex flex-end flex-col gap-4">
                  <TdMarkComplete :loading="isMarkingComplete" :model-value="td.context.currentSubSection.completed" @update:model-value="markSubSectionComplete" />
                  <UiResources v-if="td.context.currentSubSection" :resources="td.context.subSectionMeta.resources || []" />
                </div>
              </template>
            </TdHeroCard>
            <UiRule class="my-8" />
          </template>

          <!-- main content -->
          <AppMain>
            <slot />
          </AppMain>
        </div>
        <UiRule v-if="$route.name !== 'token-designer'" class="my-8" />

        <!-- bottom navigation -->
        <div
          v-if="$route.name !== 'token-designer'"
          class="mb-8 flex justify-between items-center"
        >
          <UButton
            size="sm"
            color="black"
            class="w-[260px] h-[60px] justify-center"
            :loading="isTdFormSaving"
            :disabled="!isTdFormValid || !isTdFormDirty"
            @click="saveTdForm"
          >
            Save & Commit
          </UButton>
          <nav aria-label="Prev / Next Pages">
            <div
              class="flex justify-center items-center h-[60px] gap-8 py-2 border rounded-xl border-forgd-bgd-600"
            >
              <NuxtLink
                v-if="surroundBy[0]"
                :to="surroundBy[0].to"
                class="text-forgd-primary-900 w-[260px] group flex flex-col justify-end items-end"
                :class="{ 'pr-8': !surroundBy[1] }"
              >
                <div class="flex gap-1 group-hover:gap-3 transition-all">
                  <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
                  <div class="text-sm font-semibold">
                    Prev
                  </div>
                </div>
                <div class="text-gray-500 text-sm group-hover:underline">
                  {{ surroundBy[0].label }}
                </div>
              </NuxtLink>
              <div
                v-if="surroundBy[0] && surroundBy[1]"
                class="flex min-h-full h-full w-[1px] bg-forgd-bgd-600"
              />
              <NuxtLink
                v-if="surroundBy[1]"
                :to="surroundBy[1].to"
                class="text-forgd-primary-900 w-[260px] group flex flex-col justify-start items-start"
                :class="{ 'pl-8': !surroundBy[0] }"
              >
                <div
                  class="flex items-center gap-1 group-hover:gap-2 transition-all"
                >
                  <div class="text-sm font-semibold">
                    Next
                  </div>
                  <UIcon name="i-heroicons-arrow-right" class="w-5 h-5" />
                </div>
                <div class="text-gray-500 text-sm group-hover:underline">
                  {{ surroundBy[1].label }}
                </div>
              </NuxtLink>
            </div>
          </nav>
        </div>
      </div>
    </NuxtLayout>
    <ConfirmNavigationPromise v-slot="{ resolve }">
      <UModal
        v-model="showConfirmNavigationModal"
        prevent-close
        :ui="{
          width: 'sm:max-w-[516px]',
        }"
      >
        <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
          <div class="relative">
            <div class="absolute top-5 right-5">
              <UIcon name="i-heroicons-x" class="w-6 h-6 text-forgd-primary-900" @click="resolve(false)" />
            </div>
            <div class="p-5 flex flex-col items-center font-display">
              <div class="p-5 flex flex-col items-center gap-2.5 text-center">
                <UIcon
                  name="i-heroicons-exclaimation-circle"
                  class="text-forgd-primary-300 w-10 h-10"
                />
                <div class="text-xl text-forgd-primary-900 font-semibold">
                  Are you sure you want to leave this page without saving your latest changes?
                </div>
                <p class="text-sm text-forgd-gray-600">
                  All your progress will be lost. We recommend closing this modal and saving your progress before going to a different page.
                </p>
              </div>
              <div class="grid grid-cols-2 gap-4 w-full">
                <UButton :ui="{ color: { white: { solid: 'justify-center' } } }" color="white" class="w-full" size="xl" @click="resolve(true)">
                  Leave without Saving
                </UButton>
                <UButton
                  size="lg"
                  color="primary"
                  :ui="{ color: { primary: { solid: 'hover:bg-forgd-primary-800 transition bg-forgd-primary-900 justify-center text-white' } } }"
                  class="w-full"
                  @click="resolve(false)"
                >
                  Cancel
                </UButton>
              </div>
            </div>
          </div>
        </UCard>
      </UModal>
    </ConfirmNavigationPromise>
    <TdDevCommandPalette v-if="environment !== 'production'" />
  </div>
</template>

<style>
:root {
  --vis-font-family: 'DM Mono';
  --vis-axis-font-family: 'DM Mono';
  --vis-axis-label-color: #95958D;
  --vis-axis-domain-color: #C7C7BE;
  --vis-axis-domain-line-width: 1px;
  --vis-axis-label-font-size: 10px;
  --vis-axis-tick-label-color: #95958D;
  --vis-axis-tick-label-font-size: 10px;
  --vis-tooltip-background-color: #EDEDEA;
  --vis-tooltip-border-color: #C7C7BE;
  --vis-tooltip-text-color: #001221;
  --vis-tooltip-font-size: 12px;
  --vis-tooltip-shadow-color: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.20);
  --vis-tooltip-padding: 4px 12px;
}
</style>
